export let data = [["pinlight","texture11","texture55","ae295e"],
["pinlight","texture36","texture55","ae295e"],
["difference","texture5","texture55","ae2929"],
["difference","texture9","texture55","2943ae"],
["difference","texture3","texture55","ae2929"],
["difference","texture2","texture55","ae2929"],
["difference","texture51","texture55","ae2929"],
["difference","texture49","texture55","ae2929"],
["difference","texture49","texture51","7229ae"],
["screen","texture4","texture45","473229"],
["linearlight","texture23","texture46","501207"],
["screen","texture23","texture46","402212"],
["screen","texture23","texture45","402212"],
["screen","texture23","texture32","3a1240"],
["normal","texture10","texture37","861828"],
["normal","texture9","texture37","184886"],
["normal","texture8","texture37","273649"],
["normal","texture5","texture37","273649"],
["normal","texture4","texture37","493d27"],
["normal","texture3","texture37","1a305b"],
["normal","texture2","texture37","554c2a"],
["normal","texture1","texture37","2a553f"],
["hardlight","texture83","texture37","1d2a67"],
["hardlight","texture78","texture37","1d2a67"],
["exclusion","texture74","texture33","4f2555"],
["exclusion","texture74","texture32","4f2555"],
["exclusion","texture74","texture30","403a2d"],
["overlay","texture71","texture28","3a4388"],
["overlay","texture71","texture26","3a4388"],
["linearlight","texture71","texture30","3a5488"],
["linearlight","texture71","texture28","3a5488"],
["linearlight","texture71","texture26","3a5488"],
["linearlight","texture71","texture24","3a5488"],
["linearlight","texture71","texture23","3a5488"],
["linearlight","texture71","texture21","3a5488"],
["linearlight","texture71","texture14","3a5488"],
["linearlight","texture71","texture12","88553a"],
["linearlight","texture71","texture11","88553a"],
["linearlight","texture71","texture8","3a4a88"],
["linearlight","texture71","texture6","3a4a88"],
["linearlight","texture71","texture5","3a4a88"],
["linearlight","texture71","texture4","3a4a88"],
["linearlight","texture71","texture3","3a4a88"],
["linearlight","texture71","texture15","3a4a88"],
["difference","texture70","texture15","3a4a88"],
["difference","texture71","texture15","3a4a88"],
["difference","texture73","texture15","3a4a88"],
["difference","texture74","texture15","3a4a88"],
["lineardodge","texture79","texture15","3a4a88"],
["pinlight","texture80","texture15","3a4a88"],
["difference","texture80","texture15","883a79"],
["difference","texture27","texture23","883a79"],
["vividlight","texture35","texture34","3a8858"],
["vividlight","texture35","texture38","3a8858"],
["lighten","texture36","texture38","883a59"],
["lighten","texture45","texture38","883a59"],
["difference","texture47","texture46","883a59"],
["difference","texture47","texture48","883a59"],
["difference","texture47","texture52","883a59"],
["difference","texture47","texture55","883a59"],
["difference","texture48","texture55","883a59"],
["difference","texture49","texture55","883a59"],
["difference","texture51","texture66","883a59"],
["difference","texture51","texture71","883a59"],
["difference","texture51","texture73","883a59"],
["difference","texture51","texture78","0a3171"],
["difference","texture52","texture83","0a3171"],
["difference","texture53","texture83","0a3171"],
["difference","texture60","texture83","0a3171"],
["screen","texture78","texture92","0a3171"],
["overlay","texture4","texture31","710a0a"],
["overlay","texture6","texture31","290a71"],
["overlay","texture8","texture31","290a71"],
["overlay","texture9","texture45","290a71"],
["overlay","texture10","texture45","711e0a"],
["overlay","texture12","texture45","711e0a"],
["overlay","texture24","texture45","0a1171"],
["overlay","texture28","texture45","71230a"],
["overlay","texture29","texture45","71230a"],
["overlay","texture30","texture45","715a0a"],
["overlay","texture33","texture45","200a71"],
["overlay","texture35","texture45","200a71"],
["overlay","texture36","texture45","200a71"],
["overlay","texture37","texture45","200a71"],
["overlay","texture38","texture45","710a0a"],
["overlay","texture42","texture45","710a5e"],
["overlay","texture45","texture45","70360a"],
["overlay","texture46","texture45","70360a"],
["overlay","texture51","texture45","70360a"],
["overlay","texture52","texture45","502f16"],
["overlay","texture66","texture45","502f16"],
["overlay","texture66","texture46","a1365b"],
["overlay","texture67","texture46","8936a1"],
["overlay","texture70","texture46","8936a1"],
["overlay","texture71","texture46","363da1"],
["overlay","texture73","texture46","a13698"],
["overlay","texture78","texture46","a13698"],
["overlay","texture89","texture46","a13698"],
["overlay","texture92","texture46","4236a1"],
["lineardodge","texture92","texture45","486f7f"],
["exclusion","texture97","texture45","bca929"],
["subtract","texture92","texture45","2942bc"],
["subtract","texture88","texture45","2942bc"],
["subtract","texture80","texture45","2942bc"],
["subtract","texture78","texture45","2942bc"],
["subtract","texture73","texture45","2942bc"],
["subtract","texture73","texture35","bc2929"],
["subtract","texture73","texture20","832385"],
["subtract","texture73","texture18","832385"],
["subtract","texture73","texture17","832385"],
["subtract","texture73","texture15","832385"],
["subtract","texture73","texture14","832385"],
["subtract","texture73","texture9","852323"],
["subtract","texture73","texture8","85236b"],
["subtract","texture73","texture7","85236b"],
["subtract","texture73","texture6","855323"],
["subtract","texture73","texture5","2a2385"],
["subtract","texture73","texture4","2a2385"],
["subtract","texture73","texture2","2a2385"],
["subtract","texture73","texture1","852328"],
["subtract","texture73","texture3","85235c"],
["hardlight","texture73","texture3","236085"],
["darken","texture73","texture3","236085"],
["overlay","texture73","texture3","236085"],
["normal","texture83","texture3","23856d"],
["softlight","texture83","texture3","5a83e2"],
["softlight","texture80","texture3","5a83e2"],
["softlight","texture73","texture3","5a83e2"],
["softlight","texture71","texture3","5a83e2"],
["softlight","texture70","texture3","5a83e2"],
["softlight","texture49","texture3","2e65e5"],
["subtract","texture52","texture3","e52e2e"],
["difference","texture52","texture3","4bb33d"],
["colordodge","texture52","texture3","b33d82"],
["colordodge","texture45","texture3","60b33d"],
["subtract","texture36","texture3","b33d3d"],
["linearlight","texture36","texture3","4693c3"],
["hardlight","texture36","texture3","4693c3"],
["lighten","texture33","texture3","4693c3"],
["lighten","texture24","texture3","4693c3"],
["colordodge","texture19","texture3","91c346"],
["difference","texture19","texture3","468dc3"],
["divide","texture19","texture3","c3bf46"],
["difference","texture19","texture3","4687c3"],
["lighten","texture17","texture3","4687c3"],
["lighten","texture13","texture3","4687c3"],
["lighten","texture11","texture3","4687c3"],
["lighten","texture10","texture3","4687c3"],
["lighten","texture9","texture3","4687c3"],
["lighten","texture7","texture3","4687c3"],
["lighten","texture5","texture3","4687c3"],
["lighten","texture4","texture3","4687c3"],
["lighten","texture3","texture3","4687c3"],
["lighten","texture1","texture3","4687c3"],
["difference","texture92","texture2","c34646"],
["difference","texture82","texture2","c34646"],
["difference","texture83","texture2","c346b2"],
["difference","texture80","texture2","c346b2"],
["difference","texture73","texture2","c346b2"],
["difference","texture70","texture2","c346b2"],
["difference","texture66","texture2","c346b2"],
["difference","texture64","texture2","c346b2"],
["difference","texture60","texture2","c346b2"],
["difference","texture55","texture2","c346b2"],
["difference","texture49","texture2","c345b2"],
["difference","texture46","texture2","46c37c"],
["difference","texture45","texture2","c346b2"],
["difference","texture42","texture2","c346b2"],
["difference","texture39","texture2","c346b2"],
["difference","texture38","texture2","b4c346"],
["difference","texture37","texture2","c346b2"],
["difference","texture36","texture2","c346b2"],
["difference","texture35","texture2","c346b2"],
["difference","texture34","texture2","c346b2"],
["difference","texture28","texture2","c346b2"],
["difference","texture26","texture2","c346b2"],
["difference","texture18","texture2","c346b2"],
["difference","texture15","texture2","c346b2"],
["difference","texture7","texture2","c346b2"],
["difference","texture5","texture2","c346b2"],
["difference","texture4","texture2","c346b2"],
["difference","texture3","texture2","c346b2"],
["hardlight","texture0","texture2","6a76e"],
["lighten","texture0","texture2","6a76e"],
["subtract","texture0","texture1","6a76e"],
["difference","texture0","texture1","3c52aa"],
["pinlight","texture0","texture1","3c52aa"],
["linearlight","texture0","texture1","1b359d"],
["lighten","texture0","texture1","53a9c6"],
["normal","texture0","texture1","53a9c6"],
["divide","texture2","texture1","bdd507"],
["difference","texture2","texture1","0744d5"],
["linearlight","texture2","texture1","0744d5"],
["hardlight","texture2","texture1","0744d5"],
["colordodge","texture2","texture1","d90d0d"],
["lighten","texture2","texture1","d90d0d"],
["colorburn","texture2","texture1","d90d0d"],
["linearlight","texture2","texture1","0d6fd9"],
["linearlight","texture2","texture4","d9690d"],
["hardlight","texture2","texture4","d9690d"],
["overlay","texture2","texture4","d9690d"],
["multiply","texture2","texture4","d9690d"],
["darken","texture2","texture4","d9690d"],
["normal","texture2","texture4","d9690d"]]

