uniform float time;
uniform float progress;
uniform float strength;
uniform float copies;
uniform float blur;
uniform vec2 uCenter;
uniform sampler2D uMap;
uniform vec2 resolution;
varying vec2 vUv;
varying float vNoise;
varying vec3 vPosition;
float random(vec3 scale,float seed){return fract(sin(dot(gl_FragCoord.xyz+seed,scale))*43758.5453+seed);}
void main()	{

    vec4 c = texture2D(uMap, vUv);
	gl_FragColor = c;
	// gl_FragColor = vec4(vUv,0.,1.);


    vec2 center = vec2(uCenter.x * resolution.x,uCenter.y * resolution.y);

    vec4 color=vec4(0.0);
    float total=0.0;
    vec2 toCenter=center-vUv*resolution;
    float offset=random(vec3(12.9898,78.233,151.7182),0.0);
    for(float t=0.0;t<=copies;t++){
        float percent=(t+blur*offset)/copies;
        float weight=4.0*(percent-percent*percent);
        vec4 mysample=texture2D(uMap,vUv+toCenter*percent*strength/resolution);
        mysample.rgb*=mysample.a;
        color+=mysample*weight;
        total+=weight;
    }

    gl_FragColor=color/total;
    gl_FragColor.rgb/=gl_FragColor.a+0.00001;

    // gl_FragColor = vec4(1.,0.,0.,1.);

    vec4 originalSample = texture2D( uMap, vUv );

    vec4 finalcolor = 1.0 - ( ( 1.0 - gl_FragColor ) * ( 1.0 - texture2D( uMap, vUv ) ) );

    gl_FragColor = finalcolor;

    // gl_FragColor.a = originalSample.a;


    // if(gl_FragColor.rgb == vec3(0.)) discard;
    // gl_FragColor.rgb = vec3(1.,0.,0.);
    // gl_FragColor = originalSample;



}