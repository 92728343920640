uniform float time;
uniform float uSpeed1;
uniform float uSpeed2;
uniform float uTextureBlend;
uniform float uTextureBrightness1;
uniform float uTextureBrightness2;
uniform float uTextureSaturation1;
uniform float uTextureSaturation2;
uniform sampler2D texture1;
uniform sampler2D texture2;
uniform int uBlendMode;
uniform vec3 uColor;
uniform float uColorify;
uniform vec3 uColor1;
uniform vec3 uColor2;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
float PI = 3.141592653589793238;
//	Classic Perlin 3D Noise 
//	by Stefan Gustavson
//
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
vec4 fade(vec4 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

float cnoise(vec4 P){
  vec4 Pi0 = floor(P); // Integer part for indexing
  vec4 Pi1 = Pi0 + 1.0; // Integer part + 1
  Pi0 = mod(Pi0, 289.0);
  Pi1 = mod(Pi1, 289.0);
  vec4 Pf0 = fract(P); // Fractional part for interpolation
  vec4 Pf1 = Pf0 - 1.0; // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = vec4(Pi0.zzzz);
  vec4 iz1 = vec4(Pi1.zzzz);
  vec4 iw0 = vec4(Pi0.wwww);
  vec4 iw1 = vec4(Pi1.wwww);

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);
  vec4 ixy00 = permute(ixy0 + iw0);
  vec4 ixy01 = permute(ixy0 + iw1);
  vec4 ixy10 = permute(ixy1 + iw0);
  vec4 ixy11 = permute(ixy1 + iw1);

  vec4 gx00 = ixy00 / 7.0;
  vec4 gy00 = floor(gx00) / 7.0;
  vec4 gz00 = floor(gy00) / 6.0;
  gx00 = fract(gx00) - 0.5;
  gy00 = fract(gy00) - 0.5;
  gz00 = fract(gz00) - 0.5;
  vec4 gw00 = vec4(0.75) - abs(gx00) - abs(gy00) - abs(gz00);
  vec4 sw00 = step(gw00, vec4(0.0));
  gx00 -= sw00 * (step(0.0, gx00) - 0.5);
  gy00 -= sw00 * (step(0.0, gy00) - 0.5);

  vec4 gx01 = ixy01 / 7.0;
  vec4 gy01 = floor(gx01) / 7.0;
  vec4 gz01 = floor(gy01) / 6.0;
  gx01 = fract(gx01) - 0.5;
  gy01 = fract(gy01) - 0.5;
  gz01 = fract(gz01) - 0.5;
  vec4 gw01 = vec4(0.75) - abs(gx01) - abs(gy01) - abs(gz01);
  vec4 sw01 = step(gw01, vec4(0.0));
  gx01 -= sw01 * (step(0.0, gx01) - 0.5);
  gy01 -= sw01 * (step(0.0, gy01) - 0.5);

  vec4 gx10 = ixy10 / 7.0;
  vec4 gy10 = floor(gx10) / 7.0;
  vec4 gz10 = floor(gy10) / 6.0;
  gx10 = fract(gx10) - 0.5;
  gy10 = fract(gy10) - 0.5;
  gz10 = fract(gz10) - 0.5;
  vec4 gw10 = vec4(0.75) - abs(gx10) - abs(gy10) - abs(gz10);
  vec4 sw10 = step(gw10, vec4(0.0));
  gx10 -= sw10 * (step(0.0, gx10) - 0.5);
  gy10 -= sw10 * (step(0.0, gy10) - 0.5);

  vec4 gx11 = ixy11 / 7.0;
  vec4 gy11 = floor(gx11) / 7.0;
  vec4 gz11 = floor(gy11) / 6.0;
  gx11 = fract(gx11) - 0.5;
  gy11 = fract(gy11) - 0.5;
  gz11 = fract(gz11) - 0.5;
  vec4 gw11 = vec4(0.75) - abs(gx11) - abs(gy11) - abs(gz11);
  vec4 sw11 = step(gw11, vec4(0.0));
  gx11 -= sw11 * (step(0.0, gx11) - 0.5);
  gy11 -= sw11 * (step(0.0, gy11) - 0.5);

  vec4 g0000 = vec4(gx00.x,gy00.x,gz00.x,gw00.x);
  vec4 g1000 = vec4(gx00.y,gy00.y,gz00.y,gw00.y);
  vec4 g0100 = vec4(gx00.z,gy00.z,gz00.z,gw00.z);
  vec4 g1100 = vec4(gx00.w,gy00.w,gz00.w,gw00.w);
  vec4 g0010 = vec4(gx10.x,gy10.x,gz10.x,gw10.x);
  vec4 g1010 = vec4(gx10.y,gy10.y,gz10.y,gw10.y);
  vec4 g0110 = vec4(gx10.z,gy10.z,gz10.z,gw10.z);
  vec4 g1110 = vec4(gx10.w,gy10.w,gz10.w,gw10.w);
  vec4 g0001 = vec4(gx01.x,gy01.x,gz01.x,gw01.x);
  vec4 g1001 = vec4(gx01.y,gy01.y,gz01.y,gw01.y);
  vec4 g0101 = vec4(gx01.z,gy01.z,gz01.z,gw01.z);
  vec4 g1101 = vec4(gx01.w,gy01.w,gz01.w,gw01.w);
  vec4 g0011 = vec4(gx11.x,gy11.x,gz11.x,gw11.x);
  vec4 g1011 = vec4(gx11.y,gy11.y,gz11.y,gw11.y);
  vec4 g0111 = vec4(gx11.z,gy11.z,gz11.z,gw11.z);
  vec4 g1111 = vec4(gx11.w,gy11.w,gz11.w,gw11.w);

  vec4 norm00 = taylorInvSqrt(vec4(dot(g0000, g0000), dot(g0100, g0100), dot(g1000, g1000), dot(g1100, g1100)));
  g0000 *= norm00.x;
  g0100 *= norm00.y;
  g1000 *= norm00.z;
  g1100 *= norm00.w;

  vec4 norm01 = taylorInvSqrt(vec4(dot(g0001, g0001), dot(g0101, g0101), dot(g1001, g1001), dot(g1101, g1101)));
  g0001 *= norm01.x;
  g0101 *= norm01.y;
  g1001 *= norm01.z;
  g1101 *= norm01.w;

  vec4 norm10 = taylorInvSqrt(vec4(dot(g0010, g0010), dot(g0110, g0110), dot(g1010, g1010), dot(g1110, g1110)));
  g0010 *= norm10.x;
  g0110 *= norm10.y;
  g1010 *= norm10.z;
  g1110 *= norm10.w;

  vec4 norm11 = taylorInvSqrt(vec4(dot(g0011, g0011), dot(g0111, g0111), dot(g1011, g1011), dot(g1111, g1111)));
  g0011 *= norm11.x;
  g0111 *= norm11.y;
  g1011 *= norm11.z;
  g1111 *= norm11.w;

  float n0000 = dot(g0000, Pf0);
  float n1000 = dot(g1000, vec4(Pf1.x, Pf0.yzw));
  float n0100 = dot(g0100, vec4(Pf0.x, Pf1.y, Pf0.zw));
  float n1100 = dot(g1100, vec4(Pf1.xy, Pf0.zw));
  float n0010 = dot(g0010, vec4(Pf0.xy, Pf1.z, Pf0.w));
  float n1010 = dot(g1010, vec4(Pf1.x, Pf0.y, Pf1.z, Pf0.w));
  float n0110 = dot(g0110, vec4(Pf0.x, Pf1.yz, Pf0.w));
  float n1110 = dot(g1110, vec4(Pf1.xyz, Pf0.w));
  float n0001 = dot(g0001, vec4(Pf0.xyz, Pf1.w));
  float n1001 = dot(g1001, vec4(Pf1.x, Pf0.yz, Pf1.w));
  float n0101 = dot(g0101, vec4(Pf0.x, Pf1.y, Pf0.z, Pf1.w));
  float n1101 = dot(g1101, vec4(Pf1.xy, Pf0.z, Pf1.w));
  float n0011 = dot(g0011, vec4(Pf0.xy, Pf1.zw));
  float n1011 = dot(g1011, vec4(Pf1.x, Pf0.y, Pf1.zw));
  float n0111 = dot(g0111, vec4(Pf0.x, Pf1.yzw));
  float n1111 = dot(g1111, Pf1);

  vec4 fade_xyzw = fade(Pf0);
  vec4 n_0w = mix(vec4(n0000, n1000, n0100, n1100), vec4(n0001, n1001, n0101, n1101), fade_xyzw.w);
  vec4 n_1w = mix(vec4(n0010, n1010, n0110, n1110), vec4(n0011, n1011, n0111, n1111), fade_xyzw.w);
  vec4 n_zw = mix(n_0w, n_1w, fade_xyzw.z);
  vec2 n_yzw = mix(n_zw.xy, n_zw.zw, fade_xyzw.y);
  float n_xyzw = mix(n_yzw.x, n_yzw.y, fade_xyzw.x);
  return 2.2 * n_xyzw;
}

vec3 blendMultiply(vec3 base, vec3 blend) {
	return base*blend;
}

vec3 blendMultiply(vec3 base, vec3 blend, float opacity) {
	return (blendMultiply(base, blend) * opacity + base * (1.0 - opacity));
}

mat4 brightnessMatrix( float brightness )
{
    return mat4( 1, 0, 0, 0,
                 0, 1, 0, 0,
                 0, 0, 1, 0,
                 brightness, brightness, brightness, 1 );
}

mat4 contrastMatrix( float contrast )
{
	float t = ( 1.0 - contrast ) / 2.0;
    
    return mat4( contrast, 0, 0, 0,
                 0, contrast, 0, 0,
                 0, 0, contrast, 0,
                 t, t, t, 1 );

}

mat4 saturationMatrix( float saturation )
{
    vec3 luminance = vec3( 0.3086, 0.6094, 0.0820 );
    
    float oneMinusSat = 1.0 - saturation;
    
    vec3 red = vec3( luminance.x * oneMinusSat );
    red+= vec3( saturation, 0, 0 );
    
    vec3 green = vec3( luminance.y * oneMinusSat );
    green += vec3( 0, saturation, 0 );
    
    vec3 blue = vec3( luminance.z * oneMinusSat );
    blue += vec3( 0, 0, saturation );
    
    return mat4( red,     0,
                 green,   0,
                 blue,    0,
                 0, 0, 0, 1 );
}




// =========================================================
// =========================================================
// =========================================================

//Darken
vec3 darken (vec3 target, vec3 blend){
    
 return min (target, blend);   
}

//Multiply
vec3 multiply (vec3 target, vec3 blend){
    return target*blend;
}

//Color Burn
vec3 colorBurn (vec3 target, vec3 blend){
    return 1.0 - (1.0 - target)/ blend;
}

//Linear Burn
vec3 linearBurn (vec3 target, vec3 blend){
    return target + blend - 1.0;
}

//Lighten
vec3 lighten (vec3 target, vec3 blend){
    return max (target, blend);
}

//Screen
vec3 screen (vec3 target, vec3 blend){
    return 1.0 - (1.0 - target) * (1.0 - blend);
}

//Color Dodge
vec3 colorDodge (vec3 target, vec3 blend){
    return target / (1.0 - blend);
}

//Linear Dodge
vec3 linearDodge (vec3 target, vec3 blend){
    return target + blend;
}

//Overlay
vec3 overlay (vec3 target, vec3 blend){
    vec3 temp;
    temp.x = (target.x > 0.5) ? (1.0-(1.0-2.0*(target.x-0.5))*(1.0-blend.x)) : (2.0*target.x)*blend.x;
    temp.y = (target.y > 0.5) ? (1.0-(1.0-2.0*(target.y-0.5))*(1.0-blend.y)) : (2.0*target.y)*blend.y;
    temp.z = (target.z > 0.5) ? (1.0-(1.0-2.0*(target.z-0.5))*(1.0-blend.z)) : (2.0*target.z)*blend.z;
    return temp;
}

//Soft Light
vec3 softLight (vec3 target, vec3 blend){
 vec3 temp;
    temp.x = (blend.x > 0.5) ? (1.0-(1.0-target.x)*(1.0-(blend.x-0.5))) : (target.x * (blend.x + 0.5));
    temp.y = (blend.y > 0.5) ? (1.0-(1.0-target.y)*(1.0-(blend.y-0.5))) : (target.y * (blend.y + 0.5));
    temp.z = (blend.z > 0.5) ? (1.0-(1.0-target.z)*(1.0-(blend.z-0.5))) : (target.z * (blend.z + 0.5));
    return temp;   
}

//Hard Light
vec3 hardLight (vec3 target, vec3 blend){
    vec3 temp;
    temp.x = (blend.x > 0.5) ? (1.0-(1.0-target.x)*(1.0-2.0*(blend.x-0.5))) : (target.x * (2.0*blend.x));
    temp.y = (blend.y > 0.5) ? (1.0-(1.0-target.y)*(1.0-2.0*(blend.y-0.5))) : (target.y * (2.0*blend.y));
    temp.z = (blend.z > 0.5) ? (1.0-(1.0-target.z)*(1.0-2.0*(blend.z-0.5))) : (target.z * (2.0*blend.z));
    return temp;
}

//Vivid Light
vec3 vividLight (vec3 target, vec3 blend){
     vec3 temp;
    temp.x = (blend.x > 0.5) ? (1.0-(1.0-target.x)/(2.0*(blend.x-0.5))) : (target.x / (1.0-2.0*blend.x));
    temp.y = (blend.y > 0.5) ? (1.0-(1.0-target.y)/(2.0*(blend.y-0.5))) : (target.y / (1.0-2.0*blend.y));
    temp.z = (blend.z > 0.5) ? (1.0-(1.0-target.z)/(2.0*(blend.z-0.5))) : (target.z / (1.0-2.0*blend.z));
    return temp;
}

//Linear Light
vec3 linearLight (vec3 target, vec3 blend){
    vec3 temp;
    temp.x = (blend.x > 0.5) ? (target.x)+(2.0*(blend.x-0.5)) : (target.x +(2.0*blend.x-1.0));
    temp.y = (blend.y > 0.5) ? (target.y)+(2.0*(blend.y-0.5)) : (target.y +(2.0*blend.y-1.0));
    temp.z = (blend.z > 0.5) ? (target.z)+(2.0*(blend.z-0.5)) : (target.z +(2.0*blend.z-1.0));
    return temp;
}

//Pin Light
vec3 pinLight (vec3 target, vec3 blend){
     vec3 temp;
    temp.x = (blend.x > 0.5) ? (max (target.x, 2.0*(blend.x-0.5))) : (min(target.x, 2.0*blend.x));
    temp.y = (blend.y > 0.5) ? (max (target.y, 2.0*(blend.y-0.5))) : (min(target.y, 2.0*blend.y));
    temp.z = (blend.z > 0.5) ? (max (target.z, 2.0*(blend.z-0.5))) : (min(target.z, 2.0*blend.z));
    return temp;
}

//Difference
vec3 difference (vec3 target, vec3 blend){
    return abs (target - blend);
    
}
//Exclusion
vec3 exclusion (vec3 target, vec3 blend){
    return 0.5 - 2.0*(target-0.5)*(blend-0.5);
    
}

//Subtract (thanks to Brandondorf9999)
vec3 subtract (vec3 target, vec3 blend){
    return target - blend;
}

//Divide (thanks to Brandondorf9999)
vec3 divide (vec3 target, vec3 blend){
 return target/blend;   
}





void main()	{
    vec3  I = normalize(vPosition - cameraPosition);
    float fresnel =  0. + 1.5 * pow(1.0 + dot(I, normalize(vPosition)), 3.);
    fresnel = clamp(fresnel,0.,1.);
    vec3 color = uColor;
    
	// vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
	vec4 t1 = brightnessMatrix( uTextureBrightness1 ) *saturationMatrix(uTextureSaturation1)*texture2D(texture1, fract(vUv + vec2(time*0.003*uSpeed1,0.)));
	vec4 t2 = brightnessMatrix( uTextureBrightness2 ) *saturationMatrix(uTextureSaturation2)*texture2D(texture1, fract(vUv + vec2(-time*0.001*uSpeed2,0.)));
	vec4 t3 = brightnessMatrix( uTextureBrightness2 ) *saturationMatrix(uTextureSaturation1)*texture2D(texture1, fract(vUv + vec2(time*0.001*uSpeed2,0.)));
	vec4 t4 = brightnessMatrix( uTextureBrightness2 ) *saturationMatrix(uTextureSaturation2)*texture2D(texture1, fract(vUv + vec2(-time*0.003*uSpeed2,0.)));
	vec3 finalColor = blendMultiply(t1.rgb, t2.rgb, uTextureBlend);
	finalColor = blendMultiply(finalColor.rgb, t3.rgb, uTextureBlend);
	finalColor = blendMultiply(finalColor.rgb, t4.rgb, uTextureBlend);
	gl_FragColor = vec4(finalColor + 0.3*vec3(fresnel),1.);
	// gl_FragColor = vec4(vec3(fresnel),1.);


    float noise = 0.2+cnoise(vec4(vPosition.x*5.+ time*0.1,vPosition.y*5.,vPosition.z*5. , time*0.1));
    noise = smoothstep(-0.5,0.5,noise);
    float timeScale = 0.001;
    float dScale = 0.01;
    vec2 distortion = vec2(cnoise(vec4(vPosition.x*5.+ time*timeScale,vPosition.y*5.,vPosition.z*5. , time*timeScale))*dScale,cnoise(vec4(vPosition.x*5.+ time*timeScale,vPosition.y*5.,vPosition.z*5. , time*timeScale))*dScale);
    vec4 m1 = texture2D(texture1, fract(distortion+vUv + vec2(time*0.003*uSpeed1,0.)));
    vec4 m2 = texture2D(texture2, fract(distortion+vUv + vec2(time*0.003*uSpeed1,0.)));

    vec3 upperTexture = m1.rgb;
    vec3 lowerTexture = m2.rgb;

    // colorize
    if(uColorify>0.3){
        vec3 luma = vec3( 0.299, 0.587, 0.114 );
        float v = dot( upperTexture.xyz, luma );
        upperTexture = v*uColor1;

        float v1 = dot( lowerTexture.xyz, luma );
        lowerTexture = v1*uColor2;
    }
    

    vec3 finalImage = upperTexture;
    if (uBlendMode==0) finalImage =  darken ( upperTexture,  lowerTexture	);
    else if (uBlendMode==1) finalImage = multiply ( upperTexture,  lowerTexture);
    else if (uBlendMode==2) finalImage =colorBurn ( upperTexture,  lowerTexture);
    else if (uBlendMode==3) finalImage =linearBurn ( upperTexture,  lowerTexture);
    else if (uBlendMode==4) finalImage =lighten ( upperTexture,  lowerTexture);
    else if (uBlendMode==5) finalImage =screen ( upperTexture,  lowerTexture);
    else if (uBlendMode==6) finalImage =colorDodge ( upperTexture,  lowerTexture);
    else if (uBlendMode==7) finalImage =linearDodge (upperTexture,  lowerTexture);
    else if (uBlendMode==8) finalImage =overlay ( upperTexture,  lowerTexture);
    else if (uBlendMode==9) finalImage =softLight ( upperTexture,  lowerTexture);
    else if (uBlendMode==10) finalImage =hardLight ( upperTexture,  lowerTexture);
    else if (uBlendMode==11) finalImage =vividLight ( upperTexture,  lowerTexture);
    else if (uBlendMode==12) finalImage =linearLight ( upperTexture,  lowerTexture);
    else if (uBlendMode==13) finalImage =pinLight ( upperTexture,  lowerTexture);
    else if (uBlendMode==14) finalImage =difference ( upperTexture,  lowerTexture);
    else if (uBlendMode==15) finalImage =exclusion (upperTexture, lowerTexture);
    else if (uBlendMode==16) finalImage = subtract(upperTexture, lowerTexture);
    else if (uBlendMode== 17) finalImage = divide(upperTexture, lowerTexture);

	gl_FragColor = vec4(finalImage,1.);
	// gl_FragColor = mix(m1,m2,noise);
    // vec4 finalC = mix(gl_FragColor.rgba,gl_FragColor.rgba, noise);
    gl_FragColor = mix(gl_FragColor, vec4(color,1.), fresnel);
    // gl_FragColor =vec4(vec3(smoothstep(0.5,0.51,noise)),1.);
    // gl_FragColor = m1;
}