import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import fragment from "./shader/fragment.glsl";
import fragmentFlame from "./shader/fragmentFlame.glsl";
import fragmentFlame1 from "./shader/fragmentFlame1.glsl";
import vertex from "./shader/vertex.glsl";
const random = require("canvas-sketch-util/random");
import { data } from "./data";
// console.log(data, "data");
const createInputEvents = require('simple-input-events');

import fragmentOrtho from "./ortho/fragment.glsl";
import vertexOrtho from "./ortho/vertex.glsl";

import GUI from "lil-gui";

export default class Sketch {
  constructor(options) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    this.seed = document.body.getAttribute("data-seed");
    // alert(this.seed)
    // random.setSeed(this.seed);
    this.urls = [];
    for (let i = 1; i < 99; i++) {
      this.urls.push(`final/${i}.jpg`);
    }

    let textures = [...document.querySelectorAll(".texture")];
    this.textures = this.urls.map((t) => {
      // let temp = new THREE.TextureLoader().load(t)
      // temp.minFilter = THREE.NearestFilter
      // return temp
      return t;
    });
    this.mouse = new THREE.Vector2()
    this.mouseTarget = new THREE.Vector2()
    this.scene = new THREE.Scene();
    this.sceneOrtho = new THREE.Scene();
    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
    });
    this.renderer.autoClear = false;
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.width, this.height);
    if (!urlParams.get('transparentColor')) {
      this.renderer.setClearColor(0x000000, 1);
    }
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    );

    this.event = createInputEvents(this.container);

    // var frustumSize = 10;
    // var aspect = window.innerWidth / window.innerHeight;
    // this.camera = new THREE.OrthographicCamera( frustumSize * aspect / - 2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / - 2, -1000, 1000 );
    this.camera.position.set(0, 0, 2);
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.time = 0;

    var frustumSize = 1;
    var aspect = 1;
    this.cameraOrtho = new THREE.OrthographicCamera(
      (frustumSize * aspect) / -2,
      (frustumSize * aspect) / 2,
      frustumSize / 2,
      frustumSize / -2,
      -1000,
      1000
    );
    this.cameraOrtho.position.set(0, 0, 2);

    this.dracoLoader = new DRACOLoader();
    this.dracoLoader.setDecoderPath(
      "https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/js/libs/draco/"
    ); // use a full url path
    this.gltf = new GLTFLoader();
    this.gltf.setDRACOLoader(this.dracoLoader);

    if (urlParams.get('debug')) {
      this.debug = true
    }

    if (urlParams.get('disableStars')) {
      this.disableStars = urlParams.get('disableStars')
    }
    if (urlParams.get('disableOrtho')) {
      this.disableOrtho = urlParams.get('disableOrtho')
    }
    if (urlParams.get('orthoStrength')) {
      this.orthoStrength = urlParams.get('orthoStrength')
    }
    if (urlParams.get('geometrySize')) {
      this.geometrySize = urlParams.get('geometrySize')
    }

    this.settings();
    this.isPlaying = true;
    this.mouseEvents();
    this.initOrtho();
    this.addObjects();
    this.addStars();
    this.resize();
    this.render();
    this.setupResize();

    // check URL parameter for token
    // check html attribute for token
    // load 0

    // alert(queryString)
    // alert(urlParams.get('seed'))

    if (urlParams.get('blend')) {
      this.loadTokenFromParameters({
        blend: urlParams.get('blend'),
        texture1: urlParams.get('t1'),
        texture2: urlParams.get('t2'),
        oreol: urlParams.get('oreol'),
      })
    } else {
      this.loadToken(0);
      if (urlParams.get("seed")) {
        this.loadToken(urlParams.get("seed"));
      } else {
        if (document.body.getAttribute("data-seed")) {
          this.loadToken(document.body.getAttribute("data-seed"));
        } else {
          this.loadToken(0);
        }
      }
    }

  }

  mouseEvents() {
    this.event.on('move', ({ uv }) => {
      this.mouse.x = uv[0] - 0.5
      this.mouse.y = -uv[1] + 0.5
    })
  }

  initOrtho() {
    this.baseTexture = new THREE.WebGLRenderTarget(this.width, this.height, {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
    });

    this.glowTexture = new THREE.WebGLRenderTarget(
      this.width / 6,
      this.height / 6,
      {
        minFilter: THREE.LinearFilter,
        magFilter: THREE.LinearFilter,
        format: THREE.RGBAFormat,
      }
    );

    this.materialOrtho = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        blur: { value: 1 },
        copies: { value: 40 },
        uCenter: { value: new THREE.Vector2(0.5, 0.5) },
        uMap: { value: null },
        resolution: {
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        strength: { value: this.orthoStrength || 0.75 },
      },
      // wireframe: true,
      transparent: true,
      vertexShader: vertexOrtho,
      fragmentShader: fragmentOrtho,
    });
    if (!this.disableOrtho) {
      let mesh = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(1, 1),
        this.materialOrtho
      );
      this.sceneOrtho.add(mesh);
    }
  }

  addStars() {
    this.starsScene = new THREE.Scene();

    var light = new THREE.AmbientLight(0xffffff);
    this.starsScene.add(light);
    this.starsCamera = new THREE.PerspectiveCamera(
      30,
      this.width / this.height,
      0.1,
      1000
    );

    if (this.disableStars) return


    this.starsCamera.position.x = 0;
    this.starsCamera.position.y = 0;
    this.starsCamera.position.z = 15;

    this.starsScene.position.y = -20;




    let star, starGlow2, starGlow;
    var geometry = new THREE.SphereGeometry(0.006, 0.006, 0.006);
    var material = new THREE.MeshBasicMaterial({ color: 0xaaaaaa });
    var materialglow = new THREE.MeshLambertMaterial({
      color: 0xffffff,
      opacity: 0.07,
      transparent: true,
    });
    var StarCount = 300;
    if (this.width <= 1024 || this.height <= 768) {
      materialglow.opacity = 0.3;
      StarCount = 130;
    }

    for (var i = 0; i < StarCount; i++) {
      star = new THREE.Mesh(geometry, material);
      star.position.x = Math.random() * (0 - 16) + 8;
      star.position.y = Math.random() * (0 + 6) + 17;
      star.position.z = Math.random() * (0 - 16) + 8;
      // starGlow2.class = "star";
      this.starsScene.add(star);

      starGlow = new THREE.Mesh(
        new THREE.SphereGeometry(0.015, 0.015, 0.015),
        materialglow
      );
      starGlow.position.x = star.position.x;
      starGlow.position.y = star.position.y;
      starGlow.position.z = star.position.z;
      // starGlow2.class = "starGlow";
      this.starsScene.add(starGlow);

      starGlow2 = new THREE.Mesh(
        new THREE.SphereGeometry(0.022, 0.022, 0.022),
        materialglow
      );
      starGlow2.position.x = star.position.x;
      starGlow2.position.y = star.position.y;
      starGlow2.position.z = star.position.z;
      // starGlow2.class = "starGlow2";
      this.starsScene.add(starGlow2);
    }
  }

  settings() {
    this.modes = {
      normal: -1,
      darken: 0,
      multiply: 1,
      colorburn: 2,
      linearburn: 3,
      lighten: 4,
      screen: 5,
      colordodge: 6,
      lineardodge: 7,
      overlay: 8,
      softlight: 9,
      hardlight: 10,
      vividlight: 11,
      linearlight: 12,
      pinlight: 13,
      difference: 14,
      exclusion: 15,
      subtract: 16,
      divide: 17,
    };
    let textures = {};
    this.textures.forEach((t, i) => {
      textures[`texture${i}`] = t;
    });

    this.rand1 = random.rangeFloor(0, this.textures.length);

    this.rand2 = random.rangeFloor(0, this.textures.length);
    this.rand3 = random.rangeFloor(0, Object.keys(this.modes).length);

    this.blendText = Object.keys(this.modes)[this.rand3];
    this.blendMode = this.modes[this.blendText];

    // console.log(this.blendMode, this.blendText);

    let ttt1 = `texture${this.rand1}`;
    let ttt2 = `texture${this.rand2}`;

    let that = this;
    this.settings = {
      tokenIndex: 0,
      uBlendMode: this.blendText,
      uTexture1: ttt1,
      uTexture2: ttt2,
      strength: 0.75,
      copies: 40,
      blur: 40,
      uTextureBlend: 0.5,
      uSpeed1: 1,
      uSpeed2: 1,
      uTextureBrightness1: 0.0,
      uTextureSaturation1: 1,

      uTextureBrightness2: 0.0,
      uTextureSaturation2: 1,

      oreol: "#6263db",
      colorify: 0,
      color1: "#ff0000",
      color2: "#00ff00",
    };
    if (this.debug) {
      this.gui = new GUI();
      let blend = this.gui.addFolder("blend");

      blend
        .add(this.settings, "tokenIndex", 0, data.length - 1, 1)
        .onFinishChange((val) => {
          // console.log(val,modes[val],'blendmode')
          // this.material.uniforms.uBlendMode.value = val
          this.loadToken(val);
        })
        .listen();

      blend
        .add(this.settings, "uBlendMode", this.modes)
        .onChange((val) => {
          // console.log(val, this.modes[val], "blendmode");
          this.material.uniforms.uBlendMode.value = val;
        })
        .listen();

      blend
        .add(this.settings, "uTexture1", textures)
        .onChange((val) => {
          console.log(val, "texture1");
          this.material.uniforms.texture1.value = new THREE.TextureLoader().load(
            val
          );
        })
        .listen();

      blend
        .add(this.settings, "uTexture2", textures)
        .onChange((val) => {
          this.material.uniforms.texture2.value = new THREE.TextureLoader().load(
            val
          );
          this.material.uniforms.texture2.value.needsUpdate = true;
        })
        .listen();

      blend
        .addColor(this.settings, "oreol")
        .onChange((val) => {
          this.materialFlame.uniforms.uColor.value = new THREE.Color(val);
          this.material.uniforms.uColor.value = new THREE.Color(val);
        })
        .listen();
      let blur = this.gui.addFolder("blur");
      blur.add(this.settings, "strength", 0, 2, 0.01).onChange((val) => {
        this.materialOrtho.uniforms.strength.value = val;
      });
      blur.add(this.settings, "blur", 0, 1, 0.01).onChange((val) => {
        this.materialOrtho.uniforms.blur.value = val;
      });
      blur.add(this.settings, "copies", 1, 80, 1).onChange((val) => {
        this.materialOrtho.uniforms.copies.value = val;
      });

    }


  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    this.camera.updateProjectionMatrix();
  }

  loadToken(index) {
    // console.trace('loadToken')
    // console.log(data[index]);
    this.settings.tokenIndex = index;
    // blendmode
    this.settings.uBlendMode = this.modes[data[index][0]];
    this.material.uniforms.uBlendMode.value = this.settings.uBlendMode;

    // oreol
    this.settings.oreol = "#" + data[index][3];
    this.materialFlame.uniforms.uColor.value = new THREE.Color(
      this.settings.oreol
    );
    this.material.uniforms.uColor.value = new THREE.Color(this.settings.oreol);

    this.settings.uTexture1 = `${data[index][1]}`;
    this.settings.uTexture2 = `${data[index][2]}`;

    let ind1 = parseInt(this.settings.uTexture1.substring(7));
    let ind2 = parseInt(this.settings.uTexture2.substring(7));

    console.log(ind1, ind2);

    let t1 = new THREE.TextureLoader().load(this.urls[ind1]);
    let t2 = new THREE.TextureLoader().load(this.urls[ind2]);

    t1.minFilter = THREE.NearestFilter;
    t2.minFilter = THREE.NearestFilter;

    this.material.uniforms.texture1.value = t1;
    this.material.uniforms.texture2.value = t2;

    // textures
  }

  loadTokenFromParameters({ blend, texture1, texture2, oreol }) {
    // console.log(blend,texture1,texture2,oreol,'params')

    this.settings.uBlendMode = this.modes[blend];
    this.material.uniforms.uBlendMode.value = this.settings.uBlendMode;

    // oreol
    this.settings.oreol = "#" + oreol;
    this.materialFlame.uniforms.uColor.value = new THREE.Color(
      this.settings.oreol
    );
    this.material.uniforms.uColor.value = new THREE.Color(this.settings.oreol);
    // alert(this.settings.oreol)

    this.settings.uTexture1 = `${texture1}`;
    this.settings.uTexture2 = `${texture2}`;
    // alert(this.settings.uTexture2)

    let ind1 = parseInt(this.settings.uTexture1.substring(7));
    let ind2 = parseInt(this.settings.uTexture2.substring(7));

    // console.log(ind1, ind2);

    let t1 = new THREE.TextureLoader().load(this.urls[ind1]);
    let t2 = new THREE.TextureLoader().load(this.urls[ind2]);

    t1.minFilter = THREE.NearestFilter;
    t2.minFilter = THREE.NearestFilter;

    this.material.uniforms.texture1.value = t1;
    this.material.uniforms.texture2.value = t2;

    // this.loadToken(50)

  }

  addObjects() {
    let that = this;
    // alert(this.blendMode)

    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        uTextureBlend: { value: 0.5 },
        uSpeed1: { value: 1 },
        uSpeed2: { value: 1 },
        texture1: { value: null },
        texture2: { value: null },
        uTextureSaturation1: { value: 1 },
        uTextureSaturation2: { value: 1 },
        uTextureBrightness1: { value: 0 },
        uTextureBrightness2: { value: 0 },
        uBlendMode: { value: this.blendMode },
        uColor: { value: new THREE.Color(0x6263db) },
        uColorify: { value: 0 },
        uColor1: { value: new THREE.Color(0xff0000) },
        uColor2: { value: new THREE.Color(0x00ff00) },
        resolution: { value: new THREE.Vector4() },
      },
      // wireframe: true,
      // transparent: true,
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    this.materialFlame = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        uColor: { value: new THREE.Color(0x6263db) },
      },
      // wireframe: true,
      transparent: true,
      vertexShader: vertex,
      fragmentShader: fragmentFlame,
    });

    this.materialFlame1 = new THREE.ShaderMaterial({
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
      },
      wireframe: true,
      // transparent: true,
      // vertexShader: vertex,
      // fragmentShader: fragmentFlame1,
    });

    this.geometry = new THREE.SphereBufferGeometry(this.geometrySize || 0.6, 48, 95);
    this.geometryFlame = new THREE.RingGeometry(this.geometrySize || 0.6, 1, 48);
    this.geometryFlame1 = new THREE.RingGeometry(this.geometrySize || 0.6, 0.8, 48);
    this.ring = new THREE.Mesh(this.geometryFlame, this.materialFlame);
    this.ring1 = new THREE.Mesh(this.geometryFlame1, this.materialFlame1);

    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.plane);
    this.scene.add(this.ring);
    // this.scene.add(this.ring1);
  }

  stop() {
    this.isPlaying = false;
  }

  play() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.render();
    }
  }

  render() {
    if (!this.isPlaying) return;
    this.time += 0.05;
    this.material.uniforms.time.value = this.time;
    requestAnimationFrame(this.render.bind(this));
    // this.renderer.render(this.scene, this.camera);

    if (!this.disableStars) {
      this.starsScene.rotation.y += 0.0004;
    }

    // mouseNewX = mouseX - ( (mouseX - mouseNewX) / 1.2);
    // mouseNewY = mouseY - ( (mouseY - mouseNewY) / 1.2);
    this.mouseTarget.lerp(this.mouse, 0.005);

    if (!this.disableStars) {
      this.starsCamera.position.x = this.mouseTarget.x
      this.starsCamera.position.y = this.mouseTarget.y
    }

    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
    this.renderer.clearDepth();
    this.renderer.setRenderTarget(this.baseTexture);
    this.renderer.clear();
    this.renderer.render(this.scene, this.camera);
    this.materialOrtho.uniforms.uMap.value = this.baseTexture.texture;
    this.renderer.setRenderTarget(null);
    this.renderer.render(this.sceneOrtho, this.cameraOrtho);
    this.renderer.clearDepth();
    this.renderer.render(this.starsScene, this.starsCamera);

    // this.renderer.render(this.scene, this.camera);
  }
}

new Sketch({
  dom: document.getElementById("container"),
});
